body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #282c34;
}

.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.dashboard-title {
    color: white;
    font-size: 24px;
    margin-bottom: 20px;
}

.dashboard-content {
    background-color: #282c34;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(255, 255, 255, 0.12), 0 1px 2px rgba(255, 255, 255, 0.24);
    padding: 20px;
}

.MuiFormControl-root {
    margin-bottom: 20px;
}

.MuiInputLabel-root {
    color: white;
}

.MuiSelect-select {
    background-color: #282c34;
    color: white;
}

.MuiMenuItem-root {
    color: white;
}

.MuiMenuItem-root:hover {
    background-color: #3a3f4a;
}
