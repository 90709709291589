.LoginPage {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #282c34;
    color: white;
}

.LoginPage-header {
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.LoginComponent {
  margin-top: 20px;
  position: flex;
  display: flex;
  flex-direction: column;
  top: 0px;
  display: flex;
  justify-content: top;
  align-items: center;
  height: 100vh;
  font-size: calc(10px + 2vmin);
}

.SubmitLoginButton {
  margin-top: 20px;
  background-color: gray;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}