.LandingPage {
  text-align: center;
  height: calc(100vh - 64px); /* Adjust height to account for the toolbar */
  overflow: hidden;
}
  
.LandingPage-header {
  background-color: #282c34;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
