.Payment {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34;
  color: white;
  overflow: auto;
}

.PaymentDetails {
  margin: 0;
}

.PaymentDetailLabel {
  width: 150px;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.PaymentDetailValue {
  width: 360px;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.PaymentCopyButton {
  justify-content: center;
}