.TextInputComponent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.TextInputComponentLabel {
  width: 145px;
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
  margin-right: 5px;
}